import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Camden({ styles, ...props }) {
  const Elements = useComponents()
  const accent = styles.forElement("preheader").color
  const checkboxes_color = styles.forElement("form_content_text").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div className="formkit-container">
        <div className="formkit-main" style={{ "--accent": accent }}>
          <Elements.Heading
            className="formkit-preheader"
            name="preheader"
            defaults={{ content: "Webinar subtitle goes here" }}
          />
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Region name="intro" className="formkit-intro">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this subtitle"
              }}
            />
          </Elements.Region>
          <hr />
          <Elements.Heading
            className="formkit-subheader"
            name="subheader"
            defaults={{ content: "Here is what you'll learn:" }}
          />
          <Elements.Region name="content" className="formkit-content">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this content area"
              }}
            />
          </Elements.Region>
          <Elements.BuiltWith variant="dark" />
        </div>
        <div
          className="formkit-sidebar"
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
        >
          <div
            style={styles.forElement("background")}
            className="formkit-background"
          />
          <Elements.Image
            className="formkit-avatar"
            name="image"
            size={{ w: 100, h: 100 }}
            aspectRatio={1}
            shape="circle"
          />
          <Elements.Content
            name="presented_by"
            className="formkit-presented-by"
            defaults={{ content: "Presented by" }}
          />
          <Elements.Heading
            name="presenter"
            className="formkit-presenter"
            defaults={{ content: "First Last Name" }}
          />
          <Elements.Event className="event-date" name="event" />
          <Elements.Heading
            name="form_header"
            className="formkit-form-header"
            defaults={{ content: "Register today" }}
          />
          <Elements.Region name="form_content" className="formkit-form-content">
            <Elements.Content
              defaults={{
                content:
                  "Don't miss your chance. Fill out the form below and sign up today."
              }}
            />
          </Elements.Region>
          <Elements.Form
            style={styles.forElement("form", {
              backgroundColor: "#ffffff"
            })}
          >
            <Elements.Errors />
            <Elements.CustomFields
              style={{
                color: checkboxes_color
              }}
            >
              <Elements.AddFieldButton />
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Register" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content: "We respect your privacy. Unsubscribe at any time."
            }}
          />
        </div>
      </div>
    </Elements.LandingPage>
  )
}
Camden.style = "landing_page"
Camden.categories = ["Event", "Webinar"]
Camden.thumbnail = ""
Camden.preview = "https://pages.convertkit.com/225dd1b234/c897471bb4"
Camden.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]
export default createTemplate(Camden, { name: "Camden" })
